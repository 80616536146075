$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;


.ReportSettings .colorSelector .titleDiv{
    margin: .5rem 0;
}
.ReportSettings .colorSelector .colorPalettesOuterContainer{
    margin-bottom: 1rem;
}
.ReportSettings .colorSelector .colorPalettesOuterContainer .colorPalettesInnerContainer{
    display: grid;
    gap: .25rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
}

.ReportSettings .colorSelector .colorPalettesOuterContainer .colorPalettesInnerContainer .palette{
    width: 100%;
    aspect-ratio: 1;
    content: "";
    border-radius: 6px;
    transition: 300ms ease-in-out;
    &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 .1rem $white-color;
    }

}

.ReportSettings .colorSelector .colorInputDiv{
    background: $gray-light-3-color;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border-radius: 6px;
    margin-bottom: 1rem;
}
.ReportSettings .colorSelector .colorInputDiv input{
    outline: none;
    border: none;
    background: transparent;
}
.ReportSettings .colorSelector .colorInputDiv .colorHashInput{
    width: calc( 100% - 1.5rem);
    color: $blue-light-1-color;
}
.ReportSettings .colorSelector .colorInputDiv .colorDropperInput{
    aspect-ratio: 1;
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

}
.ReportSettings .colorSelector .colorInputDiv .colorDropperInput input{
    width: 120%;
    height: 120%;
    padding: 0;
    opacity: 0;
}
.ReportSettings .colorSelector .colorInputDiv hr{
    width: 0.1rem;
    height: 1.5rem;
    margin: 0 .6rem;
    background: $blue-light-1-color;
}
.ReportSettings .colorSelector .colorInputDiv .colorOpacityInput{
    width: 3rem;
    display: flex;
    align-items: center;
}
.ReportSettings .colorSelector .colorInputDiv .colorOpacityInput input{
    width: 2rem;
}
.ReportSettings .colorSelector .titleDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    white-space: nowrap;
}
.ReportSettings .colorSelector .titleDiv span{
    margin-right: auto;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}