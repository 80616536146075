$primary-color: #3f4254;
$secondary-color: #7e8299;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;

.CustomDropdown {
  position: relative;
  width: 100%;
}
.CustomDropdown .mainTitle {
  background-color: $gray-light-3-color;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
  color: $primary-color;
  border: none;
  margin: 0.5rem 0;
  cursor: pointer;
  user-select: none;
}
.CustomDropdown .mainTitle .textDiv {
  width: calc(100% - 1.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CustomDropdown .mainTitle.searchDiv input.searchInput {
  width: calc(100% - 0.75rem);
  margin-right: 0.75rem;
  background: $gray-light-3-color;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 0 !important;
}
.CustomDropdown .mainTitle img {
  height: 30%;
  transition: 200ms ease-in-out;
  margin-left: auto;
}
.CustomDropdown .mainTitle img.open {
  transform: rotate(180deg);
}
.CustomDropdown .mainTitle .dropDownarrow {
  height: 30%;
  transition: 200ms ease-in-out;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CustomDropdown .mainTitle .dropDownarrow.open {
  transform: rotate(180deg);
}

.CustomDropdown .fullDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background-color: #00A3FF;
}
.CustomDropdown .dropDownArray {
  z-index: 5;
  max-height: 0px;
  position: absolute;
  width: 95%;
  margin: 0 2.5%;
  background-color: $gray-light-2-color;
  box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.CustomDropdown .dropDownArray.open {
  min-height: 3rem;
  max-height: 15em;
  overflow: auto;
  transition: max-height 300ms ease-in-out;
}
.CustomDropdown .dropDownArray.upperDropDown {
  bottom: 3rem;
}
.CustomDropdown .dropDownArray .dropDownItems {
  padding: 0.25rem 1rem;
  cursor: pointer;
  background-color: $white-color;
  transition: 300ms ease-in-out;
}
.CustomDropdown .dropDownArray .dropDownItems.selected {
  background-color: $gray-light-1-color;
}
.CustomDropdown .dropDownArray .dropDownItems:hover {
  background-color: $gray-light-2-color;
}
.CustomDropdown .dropDownArray .dropDownItems:nth-child(1) {
  padding-top: 0.5rem;
  border-radius: 6px 6px 0 0;
}
.CustomDropdown .dropDownArray .dropDownItems:nth-last-child(1) {
  padding-bottom: 0.5rem;
  border-radius: 0 0 6px 6px;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapperOuter {
  background-color: $gray-light-2-color;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper {
  padding: 0.5rem;
  background-color: $gray-light-1-color;
  border-radius: 6px;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper .nestedDropDownGroupTitle {
  padding-left: 0.5rem;
  // padding-bottom: 0.75rem;
  font-weight: 500;
  display: flex;
  gap: .5rem;
  cursor: pointer;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper .nestedDropDownGroupTitle .groupDropDownTitle {
  width: calc(100% - 2rem);
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper .nestedDropDownGroupTitle .groupDropDownTitleArrow {
  width:1rem;
  transition: .2s ease-in-out;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper.open .nestedDropDownGroupTitle .groupDropDownTitleArrow {
  transform: rotate(180deg);
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper .nestedItemsContainer {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper.open .nestedItemsContainer {
  display: flex;
  max-height: 9999em;
  transition: max-height 3s ease-in-out;
}
.CustomDropdown .dropDownArray .nestedDropDownGroupWrapper .nestedItemsContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s cubic-bezier(0, 1.21, 0.06, 1.05);
  .dropDownItems {
    border-radius: 6px;
    border: none;
    &:nth-child(1) {
      margin-top: 0.5rem;
    }
    &.selected {
      background-color: $gray-light-3-color;
      background-color: $blue-light-2-color;
      color: $blue-color;
      font-weight: 500;
    }
  }
}
