/* @media screen and (min-device-width: 820px) {
  .fullWidthStyles {
    width: 35vw;
    overflow: auto;
  }
  
}

@media screen and (max-device-width: 820px) {
  .fullWidthStyles {
    width: 90vw;
    overflow: auto;
  }
  
} */

/* .fullWidthStyles {
  width: 100vh;
  overflow: auto;
} */

.marginRight10 {
  margin-right: 10px;
}

.overflowChartStyles {
  overflow: auto;
  margin: 0 auto;
}

.multiCardHead {
  height: 65px;
}

.titleStyles {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
  margin: 0 auto 20px auto;
}

.chartStyles {
  display: flex;
  flex-wrap: wrap;
  height: 10vh;
  justify-content: space-between;
}

.cursorPointer {
  cursor: pointer;
}

.pieStyles {
  display: flex;
  justify-content: center;
}

.titleDiv div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleDiv input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: transparent;
  padding: 0;
  width: 50px;
  margin-right: 10px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContent {
  cursor: default;
  display: none;
  position: absolute;
  right: 10px;
  top: 20px;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 6px;
}
.dropdownContentTop {
  top: auto;
  bottom: 20px;
  right: auto;
  left: 30px;
}

.colorRect {
  width: 50px;
  height: 27px;
  margin-right: 10px;
  border: 1px solid black;
  background-image: linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% );
}

.titleDiv .dropdownContent {
  display: none;
}

.pieDiv {
  display: flex;
  justify-content: space-between;
  font-family: Poppins, Helvetica, "sans-serif";
}

.pieLegend {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  max-height: 300px;
  min-width: 70px;
  /* overflow: auto; */
}

.pieLegend p {
  text-align: center;
  font-weight: bold;
}

.pieLegend .glyphBox {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-right: 15px;
  min-height: 16px;
}

.pieLegend .glyph {
  height: 10px;
  width: 10px;
  flex-basis: 10px;
  flex-shrink: 0;
  /* margin-right: 10px; */
  border: 1px solid #000000aa;
}

.pieLegend span {
  display: block;
  margin-left: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Date Range Helper */
.dateRangeHead {
  padding: 5px;
  border: 1px solid #009ef7;
  border-radius: 3px;
  color: #009ef7;
}

.dateRangeHead svg {
  width: 10px;
  height: auto;
  margin-left: 5px;
  fill: #009ef7;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.buttonGroup.vertical {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
}

.fitContent {
  width: fit-content !important;
}

.buttonGroup input {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
  margin: 0;
}

.buttonGroup label {
  margin: 0;
  border: 1px solid #009ef7;
  color: #009ef7;
  padding: 5px;
  text-align: center;
}
.buttonGroup label:nth-child(2) {
  border-radius: 3px 0 0 3px;
}
.buttonGroup label:nth-last-child(1) {
  border-radius: 0 3px 3px 0;
}
.buttonGroup.vertical label:nth-child(2) {
  border-radius: 3px 3px 0 0;
}
.buttonGroup.vertical label:nth-last-child(1) {
  border-radius: 0 0 3px 3px;
}

.buttonGroup label:hover {
  cursor: pointer;
}

.buttonGroup label.checked {
  background-color: #009ef788;
  color: #ffffff;
}

.chartLabel {
  /* font-family: Poppins, Helvetica, "sans-serif"; */
  /* font-family: "Times New Roman", Helvetica, "sans-serif"; */
  /* fill: #7e8299; */
  /* font-weight: bold; */
  /* font-size: 14px; */

}
.leftAxisTicks text {
  /* font-weight: 600; */
  /* fill: #7e8299; */
  transform: translate(-10px, 0);
  /* font-family: Poppins, Helvetica, "sans-serif"; */
  transform-box: fill-box;
  transform-origin: center;
  transform: translate(0, 5px) rotateZ(-15deg);
}
.bottomAxisTicks text {
  /* font-weight: 600; */
  transform: rotateZ(-45deg);
  /* fill: #7e8299; */
  /* font-family: Poppins, Helvetica, "sans-serif"; */
  transform-box: fill-box;
  transform-origin: center;
  transform: translate(0, 5px) rotateZ(-15deg);
}
.bottomAxisTicks.noRotate text {
  transform: none;
}

.scroll {
  overflow-x: auto;
}

.scroll::-webkit-scrollbar {
  height: 1rem;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #EFF2F1;
}

.crosshair {
  position: absolute;
  top: 0;
  left: 0;
}
.crosshair.horizontal {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #35477d;
}
.crosshair.vertical {
  height: 100%;
  width: 1px;
  border-left: 1px dashed #35477d;
}

.toogleBtnContianer {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  overflow:hidden;
}

.toogleBtn {
  padding: 8px 8px;
  font-size: 12px;
  color: #1f2937;
  outline: none;
  border: 0;
  background-color: #f3f4f6;
  overflow: hidden;
  cursor: pointer;
}

.toogleBtn:hover:not(.active) {
  background-color: #d4d4d8;
}

.active{
  background-color: #009ef7;
  color: #ffffff;
}
