$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


/* style for insight table */

.AnalysisIndex .insight-table-row {
  display:flex;
  gap: 18px;
}

.AnalysisIndex .insight-table-column-header-left {
  background-color: white;
  border-radius: 8px;
  color: #464E5F;
  
  // overflow: hidden;
}
.AnalysisIndex .insight-table-column-header-right {
  background-color: white;
  border-radius: 8px;
  text-align: center;
  color: #464E5F;
  // overflow: hidden;
}

.AnalysisIndex .insight-table-data-cell-left {
  background-color: white;
  border-radius: 8px;
  word-wrap: break-word;
  color: #7E8299;
  padding: 1rem !important;
  // overflow: hidden;
}

.AnalysisIndex .insight-table-data-cell-left .dateFormatedTimeFrameDetails{
  font-size: .8rem;
}
.AnalysisIndex .insight-table-data-cell-right {
  background-color: white;
  border-radius: 8px;
  text-align: center;
   word-wrap: break-word;
  color: #7E8299;
  display: flex; 
  align-items: center;  
  justify-content: center;
  // overflow: hidden;
}
/* .container-fluid{
  overflow: auto;
} */
/* style for inisght table */
.AnalysisIndex .groupBackGround{
  background-color: $gray-light-2-color;
  border-radius: 6px;
}
.AnalysisIndex .twoColumnDivContainer{
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  background-color: $gray-light-2-color;
  border-radius: 6px;
  padding: .5rem;
}


.AnalysisIndex .statisticalDivContainer{
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 7.5rem 7.5rem;
  grid-template-columns: 1fr auto auto;
  background-color: $gray-light-2-color;
  border-radius: 6px;
  padding: .5rem;
}

.AnalysisIndex .maximaMinimaDivContainer{
  display: grid;
  gap: 0.5rem;  
  grid-template-columns: 3fr 2fr 2fr 2fr;
  background-color: $gray-light-2-color;
  border-radius: 6px;
  padding: .5rem;
}

/* style for mobile  */
@media (min-width: 320px) and (max-width: 480px) {
  .AnalysisIndex .insight-row-width-adjustment{
   width: 24% !important;
  }
  .AnalysisIndex .insight-table-row{
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
  }
}
/* style for mobile   */


/* style for tablet  */
@media (min-width: 600px) and (max-width: 1280px) {
  .AnalysisIndex .insight-row-width-adjustment{
    width: 24% !important;
  }
  
}
/* style for mobile   */
