$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;


.AnalysisIndex .mainContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 1.5rem;
}
.AnalysisIndex .mainTitleDiv {
    display: flex;
    flex-direction: column;
    height: 4rem;
    padding: 0 0.5rem;
    // justify-content: space-between;
    // align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
}

.AnalysisIndex .mainTitleDiv .mainTitle{
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    gap: .5rem;
}
.AnalysisIndex .mainTitleDiv .mainTitle span{
    font-size: 1rem;
    color: $secondary-color;
}
.AnalysisIndex .mainTitleDiv .mainDescription{
    padding: 0 0.1rem;
    font-size: .8rem;
    color: $secondary-color;
}
.AnalysisIndex .mainContainer .scrollableDiv{
    background: $gray-light-2-color;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 4rem);
    padding: .25rem;
    gap: 0.25rem;
    overflow: auto;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv{
    
    margin: .25rem;
    padding: .5rem 1rem;
    border-radius: 6px;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv:nth-child(1){
    margin-top: .5rem;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv:nth-last-child(1){
    margin-bottom: .5rem;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv .contentTitle{
    font-weight: 500;
    font-size: 1.2rem;
    padding: .5rem 0;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv .contentSecondTitle{
    margin-top: .5rem;
    font-weight: 500;
    letter-spacing: .05rem;
    font-size: 1.1rem;
    padding: .5rem 0;
}
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv .contenttext{
    font-weight: 400;
    color: $secondary-color;
}
// .AnalysisIndex .mainContainer .scrollableDiv .contentDiv .contenttext span{
//     font-weight: 500;
//     color: $primary-color;
// }
.AnalysisIndex .mainContainer .scrollableDiv .contentDiv .contenttext hr{
    margin: .5rem 0;
}

.AnalysisIndex .columnSelectionDiv{
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0 2.5rem;
    align-items: center;
}
.AnalysisIndex .columnSelectionDiv .selectionDivTitle{
    font-size: 1.2rem;
    width: 10rem;
}
.AnalysisIndex .columnSelectionDiv .selectDiv{
    width: calc(100% - 11rem);
}
.AnalysisIndex .PopUpDivChild.AnalysisIndexDivContainer .popUpOuterContaier{
    z-index: 150;
}

@media screen and (max-width: 480px) {
    .AnalysisIndex .PopUpDivChild.AnalysisIndexDivContainer .popUpOuterContaier .PopUpInnerContaier{
        max-width: 100%;
        min-height: 100%;
        font-size: 70%;
    }
    .AnalysisIndex .insight-accordion-container{
        padding: 0;
    }
  }
.AnalysisIndex .PopUpDivChild.AnalysisIndexDivContainer .popUpOuterContaier .PopUpInnerContaier .popupTitle{
    font-size: 1.8rem;  
}