@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#d92232 ;
$red-2-color:#c71624;
$red-3-color:#a7111e;


.ExportReportComp{
    text-align: left;
    font-family: Poppins, Helvetica, "sans-serif";
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    font-size: 4mm;
    padding: 2mm;
    background-color: $white-color;
}
.ExportReportComp main{
    display: grid;
    gap: 3mm;
}
.ExportReportComp section{
    width: 21cm;
    
    border-radius: 6px;
    border: 1px solid $black-hover-color;
    padding: 0.75in;
    display: grid;
    gap: 3mm;
    grid-template-rows: auto 1fr auto;
}

.ExportReportComp .reportFrontPage{
    width: 100%;
    height:  29.7cm;
    height:  28cm;
    display: flex;
    flex-direction: column;
    // display: grid;
    // grid-template-rows: 1fr 1fr 1fr;
}   
.ExportReportComp .reportFrontPage .heroChartImg{
    width: 100%;
    // padding: 0 1rem;
}
.ExportReportComp .reportFrontPage .reportNameContainer{
    text-align: center;
    color: #ffc222;
    font-size: 3rem;
    font-weight: 600;
    padding: 0 1rem;
}
.ExportReportComp .reportFrontPage .reportNameContainer{
    text-align: center;
    color: #ffc222;
    font-size: 3rem;
    font-weight: 600;
    padding: 0 1rem;
}
.ExportReportComp .reportFrontPage .reportDiscription{
    text-align: center;
    color: $blue-light-1-color;
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 1rem;
}
.ExportReportComp .reportFrontPage .reportTimeframeContainer{
    margin-top: auto;
    color: #241c6f;
    text-align: center;
    // margin-bottom: 4rem;
}
.ExportReportComp .reportFrontPage .reportTimeframeContainer .reportTimeframeTitle{
    font-size: 2rem;
    font-weight: 600;
}
.ExportReportComp .reportFrontPage .reportTimeframeContainer .reportTimeframe{
    color: #241c6f;
    font-size: 1.8rem;
    font-weight: 400;
}
// .ExportReportComp .reportFrontPage .reportNameContainer{
//     grid-row: 1 / span 2;
//     display: flex;
//     align-items: flex-end;
//     font-size: 4rem;
//     font-weight: 600;
//     position: relative;
// }   
// .ExportReportComp .reportFrontPage .reportNameContainer .reportNameBackGround{
//     background-color: $black-hover-color;
//     content: "";
//     height: 100%;
//     width: 80%;
//     z-index: 1;
//     position: absolute;
// }
// .ExportReportComp .reportFrontPage .reportNameContainer .reportName{
//     z-index: 2;
//     padding: 0 2rem;
// }
// .ExportReportComp .reportFrontPage .reportDiscriptionContainer{
//     position: relative;
// }   
// .ExportReportComp .reportFrontPage .reportDiscriptionContainer .reportDiscriptionBackGround{
//     background-color: $black-hover-color;
//     content: "";
//     height: 100%;
//     width: 20%;
//     z-index: 1;
//     position: absolute;
//     right: 0;
// }
// .ExportReportComp .reportFrontPage .reportDiscriptionContainer .reportDiscription{
//     position: relative;
//     z-index: 2;
//     padding: 1rem 2rem;
// }
@page {
    border : 1px solid black; 
  }
@media print{
    body{
        background-color: $white-color !important;
    }
    .ExportReportComp{
        padding: 0;
        background-color: $white-color;
    }
    .ExportReportComp section{
        padding: none;
        border: none;
    }
    .ExportReportComp main{
        display: grid;
        gap: 0;
    }
    div.chapter, div.appendix {
        page-break-after: always;
    }
    .ExportReportComp .newPage{
        page-break-before: always;
    }
    .ExportReportComp .filterMsg{
        box-shadow: 0 0 1px 1px $black-hover-color,
    }
    .ExportReportComp .newPageauto{
        page-break-before: auto;
    }

    }