$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

// Scrolll

/* firefox */
//firefox

.ExportReportComp{
	$scrollbar-2-thumb-width: 0;
	$scrollbar-2-thumb-color: $secondary-color;
	$scrollbar-2-track-color: $black-hover-color  ;
	
    // Chrome & Opera
    & ::-webkit-scrollbar {
       width: $scrollbar-2-thumb-width;
        height: $scrollbar-2-thumb-width;
        //  display: none;
       &-track {
           background-color: $scrollbar-2-track-color;
       }

       &-thumb {
          background-color: $scrollbar-2-thumb-color;
       }
		 
		 &-track,
		 &-thumb {
			 border-radius: 12px;
		 }
    }
    

    // Firefox
    scrollbar-width: thin !important;
    scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color !important;
    & :hover{
        scrollbar-width: thin !important;
        scrollbar-color: $scrollbar-2-thumb-color $scrollbar-2-track-color !important;
    }
}



// /*
//  *  STYLE 8
//  */

//  .chartPopUp ::-webkit-scrollbar-track
//  {
//      border: 1px solid black;
//      background-color: #F5F5F5;
//  }
 
//  .chartPopUp ::-webkit-scrollbar
//  {
//      width: 10px;
//      background-color: #F5F5F5;
//  }
 
//  .chartPopUp ::-webkit-scrollbar-thumb
//  {
//      background-color: #000000;	
//  }
 
 
/*
 *  STYLE 7
 */

// .chartPopUp ::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: #F5F5F5;
// 	border-radius: 10px;
// }

// .chartPopUp ::-webkit-scrollbar
// {
// 	width: 10px;
// 	background-color: #F5F5F5;
// }

// .chartPopUp ::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	background-image: -webkit-gradient(linear,
// 									   left bottom,
// 									   left top,
// 									   color-stop(0.44, rgb(122,153,217)),
// 									   color-stop(0.72, rgb(73,125,189)),
// 									   color-stop(0.86, rgb(28,58,148)));
// }


 
 /*
  *  STYLE 9
  */
 
//  #style-9::-webkit-scrollbar-track
//  {
//      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//      background-color: #F5F5F5;
//  }
 
//  #style-9::-webkit-scrollbar
//  {
//      width: 10px;
//      background-color: #F5F5F5;
//  }
 
//  #style-9::-webkit-scrollbar-thumb
//  {
//      background-color: #F90;	
//      background-image: -webkit-linear-gradient(90deg,
//                                                rgba(255, 255, 255, .2) 25%,
//                                                transparent 25%,
//                                                transparent 50%,
//                                                rgba(255, 255, 255, .2) 50%,
//                                                rgba(255, 255, 255, .2) 75%,
//                                                transparent 75%,
//                                                transparent)
//  }
 