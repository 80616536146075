$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$yellow-color:#bfd400;


$componetName:"charts";


$ContainerID : $componetName+"ToolTipInitiatorID";
$InnerWapperID : $componetName+"ToolTipInnerWapperID";
$mainID : $componetName+"ToolTip";
$finalID : "#" +$ContainerID + " #" +$InnerWapperID + " #"+$mainID;


#{$finalID} {
    position: fixed;
    top: 0;
    left: 0;
    padding: .5rem .75rem;
    border-radius: 6px;
    z-index: 99999;
    background-color: white;
    box-shadow: 3px 3px 5px $black-hover-color;
    opacity: 0;
    transition: 300ms ease-in-out;
    scale: 0;
    max-width: 20rem;
}

#{$finalID}.show {
    scale: 1;
    opacity: 1;
}
#{$finalID} .title {
    font-size: 1rem;  
    font-weight: 500; 
}
#{$finalID} .discription {
 font-size: .8rem;   
}
#{$finalID} .warning {
    color: $yellow-color;
 font-size: .8rem;   
}