
$borderColor: #00000027;

.PivotTable {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border: solid #00000024 1px;
position: relative;
}

.PivotTable .tableGrid {
    display: inline-grid;
  

}

.PivotTable .tableCell {
    border: solid #00000024 1px;
    padding: .5rem;
}
.PivotTable .barTypeTable {
    display: flex;
    align-items: center;
}




