$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$gray-light-4-color: #EEF0F8;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;



.reportWrapper .reportWrapperSection .reportDraggableContainer .reportItem.reportChartItem .filterMsg {
    background-color: $white-color;
    padding: .5rem 1rem ;
    border-radius: 6px;
    word-wrap: break-word;
}