.PartyPopperContainer{
    position: absolute;
    bottom: 0;
    transition: 200ms ease-in-out;
}
.PartyPopperContainer.hide{
    opacity: 0;
}
  .PartyPoppers {
    width: 240px;
    margin:150px auto 0;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
    span {
      color: #333;
      font-size: .9em;
    }
    i {
      position: absolute;
      display: block;
      left: 50%;
      top: 0;
      width: 3px;
      height: 5px;
      background: red;
      opacity: 0;
    }
      @for $i from 1 through 50 {
        i:nth-of-type(#{$i}) {
          transform: translate3d(random(190) - 100 + px,random(50) - 100 + px,0) rotate(random(360) + deg);
          background: hsla(random(360),100%,50%,1);
          animation: bang 1000ms ease-out infinite;
          opacity: 0;
        }
      }
  }
  @keyframes bang {
    from {
      transform: translate3d(0,0,0);
      opacity: 1;
    }
  }