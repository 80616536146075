/* styles for inisght accordions */

.AnalysisIndex .inisght-accordions {
  background-color: #f5f8fa !important;
  color: #464E5F !important;
  font-size: 16px !important;
  z-index: 1;
 
}
.AnalysisIndex .accordion.accordion-flush{
  display: grid;
  gap: 1rem;
}
.AnalysisIndex .accordion.accordion-flush .accordion-item{
  border-radius: 6px;
  overflow: hidden;
}
.AnalysisIndex .accordion.accordion-flush .inisghtAccordionsHeaderTitle{
font-size: 1.5rem;
}
.AnalysisIndex .inisght-accordions-body {
  background-color: #f5f8fa !important;
}
.AnalysisIndex .insight-accordion-container {
  display: flex;
  justify-content: center;
}


/* styles for side-by-side table */

.AnalysisIndex .insight-side-by-side-table-heading {
  background-color: white;
  color: #464E5F !important;
  border-radius: 8px;
}
.AnalysisIndex .insight-side-by-side-table-container {
  display:flex;
  gap: 5px;   /* gap: 18px  figma*/
}


/* responsive for mobile */

@media (min-width: 320px) and (max-width: 480px) {
  .AnalysisIndex .insight-accordion-container{
    padding: 0px !important;
  }
.AnalysisIndex .inisght-accordions{
  font-size: 10px !important;
}

  .AnalysisIndex .insight-side-by-side-table-container {
    display:flex;
    gap: 5px; 
    flex-direction: column;
  }
  .AnalysisIndex .insight-side-by-side-table-heading {
    margin-left: 1rem !important;
  }
}


/* responsive for tablet */
@media (min-width: 601px) and (max-width: 1280px) {
  .AnalysisIndex .inisght-accordions{
    font-size: 12px !important;
  }
  .AnalysisIndex .insight-side-by-side-table-heading {
    margin-left: 1.25rem !important;
  }
}

