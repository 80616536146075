$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;

.chartPopUp .MultiselectWithApi{
    display: flex;
    flex-direction: column;
    // margin: 1rem 0;
    margin-top: 0.25rem;
    gap: .25rem;
}
.chartPopUp .MultiselectWithApi .inputDiv{
    display: flex;
    border:solid $gray-light-1-color 1px;
    box-shadow: 2px 2px 10px -3px $black-hover-color;
    width: 100%;
    flex-wrap: wrap;
    padding: .5rem;
    border-radius: 6px;
    gap: .5rem;
}
.chartPopUp .MultiselectWithApi .inputDiv .selectedItems{
    background-color: $blue-color;
    color: $white-color;
    padding: .25rem .75rem ;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 6px;
    border-radius: 18px;
    display: flex;
    gap: .5rem;
    align-items: center;
}
.chartPopUp .MultiselectWithApi .inputDiv .selectedItems .remove{
    cursor: pointer;
    user-select: none;
    width: 1.1rem;
    border-radius: 2rem;
    border: solid $white-color 1px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;
    font-size: .8rem;
}
.chartPopUp .MultiselectWithApi .inputDiv .selectedItems .remove img{
    width: 100%;
    height: 100%;
}
.chartPopUp .MultiselectWithApi .inputDiv input{
    border: none;
    outline: none;
    padding: 0.5rem;
}
.chartPopUp .MultiselectWithApi .optionOuterContainer{
    position: relative;
}
.chartPopUp .MultiselectWithApi .optionsContainer{
    display: flex;
    position: absolute;
    width: 100%;
    background-color: $white-color;
    flex-direction: column;
    gap: 1px;
    border-radius: 6px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.chartPopUp .MultiselectWithApi .optionsContainer.open{
    border:solid $gray-light-1-color 1px;
    box-shadow: 2px 2px 10px -3px $black-hover-color;
    max-height: 20rem;
    overflow: auto;
    z-index: 1;
    transition: max-height 300ms ease-in-out;
}

.chartPopUp .MultiselectWithApi .optionsContainer .options{
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    gap: .5rem;
    // font-weight: 500;
    transition: 50ms ease-in-out;
}
.chartPopUp .MultiselectWithApi .optionsContainer .options:hover{
    background-color: $gray-light-3-color;
    color: $blue-color;
    cursor: pointer;
}
.chartPopUp .MultiselectWithApi .optionsContainer .options .title{
    width: 100%;
}
.chartPopUp .MultiselectWithApi .optionsContainer .options .optionCheckBox{
    border-radius: 4px;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border:solid $secondary-color 1px;
    font-size: 1.5rem;
}
.chartPopUp .MultiselectWithApi .optionsContainer .options .optionCheckBox.checked{
    background-color: $blue-color;
    color: $white-color;
}
.chartPopUp .MultiselectWithApi .optionsContainer .lodingDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 3rem;
}
.chartPopUp .MultiselectWithApi .optionsContainer .lodingDiv img{
    height: 200%;
}

.MultiselectWithApi{
    display: flex;
    flex-direction: column;
    // margin: 1rem 0;
    margin-top: 0.25rem;
    gap: .25rem;
}
.MultiselectWithApi .inputDiv{
    display: flex;
    border:solid $gray-light-1-color 1px;
    box-shadow: 2px 2px 10px -3px $black-hover-color;
    width: 100%;
    flex-wrap: wrap;
    padding: .5rem;
    border-radius: 6px;
    gap: .5rem;
}
.MultiselectWithApi .inputDiv .selectedItems{
    background-color: $blue-color;
    color: $white-color;
    padding: .25rem .75rem ;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 6px;
    border-radius: 18px;
    display: flex;
    gap: .5rem;
    align-items: center;
}
.MultiselectWithApi .inputDiv .selectedItems .remove{
    cursor: pointer;
    user-select: none;
    width: 1.1rem;
    border-radius: 2rem;
    border: solid $white-color 1px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MultiselectWithApi .inputDiv .selectedItems .remove img{
    width: 100%;
    height: 100%;
}
.MultiselectWithApi .inputDiv input{
    border: none;
    outline: none;
    padding: 0.5rem;
}
.MultiselectWithApi .optionOuterContainer{
    position: relative;
}
.MultiselectWithApi .optionsContainer{
    display: flex;
    position: absolute;
    width: 100%;
    background-color: $white-color;
    flex-direction: column;
    gap: 1px;
    border-radius: 6px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .8s cubic-bezier(0, 1.21, 0.06, 1.05);
}
.MultiselectWithApi .optionsContainer.open{
    border:solid $gray-light-1-color 1px;
    box-shadow: 2px 2px 10px -3px $black-hover-color;
    max-height: 20rem;
    overflow: auto;
    z-index: 1;
    transition: max-height 300ms ease-in-out;
}

.MultiselectWithApi .optionsContainer .options{
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    gap: .5rem;
    // font-weight: 500;
    transition: 50ms ease-in-out;
}
.MultiselectWithApi .optionsContainer .options:hover{
    background-color: $gray-light-3-color;
    color: $blue-color;
    cursor: pointer;
}
.MultiselectWithApi .optionsContainer .options .optionCheckBox{
    border-radius: 4px;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border:solid $secondary-color 1px;
    font-size: 1.5rem;
}
.MultiselectWithApi .optionsContainer .options .optionCheckBox.checked{
    background-color: $blue-color;
    color: $white-color;
}
.MultiselectWithApi .optionsContainer .lodingDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 3rem;
}
.MultiselectWithApi .optionsContainer .lodingDiv img{
    height: 200%;
}