$primary-color: #3F4254;
$secondary-color: #7E8299;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;




.PopUpDiv.FilterDiv .popUpOuterContaier{
    position: fixed;
    left: 0;
    top: 0;
    background-color: $black-hover-color;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.PopUpDiv.FilterDiv .popUpOuterContaier .PopUpInnerContaier{
    background-color: $white-color;
    padding: 1rem;
    min-width: 60%;
    max-width: 80%;
    min-height: 50%;
    max-height: 80%;
    border-radius: 6px;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.PopUpDiv.FilterDiv .popUpOuterContaier .PopUpInnerContaier  .closeButton{
    background-color: $gray-light-2-color;
    color: $secondary-color;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }
  .PopUpDiv.FilterDiv .popUpOuterContaier .PopUpInnerContaier  .closeButton:hover{
    background-color: $blue-color;
    color: $white-color;
  }