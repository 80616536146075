.legend {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  padding: 8px 12px;
  background-color: #f9fafb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .legend {
    flex-direction: row;
    flex-wrap: wrap;
    /* display: none; */
  }
}
.legend2 {
  margin-top: 20px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 8px;
}

.tooltip {
  position: absolute;
  display: flex;
  min-width: 140px;
  max-width: 350px;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  background: white;
  border-radius: 0.375rem;
}
.tooltip > div {
  display: flex;
  flex-direction: column;
  /* font-weight: 700; */
}

.iconBtn {
  height: 28px;
  width: 28px;
  color: black;
  outline-width: 0;
  border: 0;
  background-color: #f9fafb;
}
.iconBtn:hover {
  background-color: #d1d5db;
  border-radius: 0.375rem;
}

.iconBtn > i {
  color: black;
}
